







































import { defineComponent, reactive, toRefs } from "@vue/composition-api"
import {
    checkMissionTemplateRelatePlaceVaild,
    InvaildPlaceField,
} from "@/services/MissionTemplatesService"

export default defineComponent({
    name: "MissionTemplatePlaceChecker",
    setup() {
        const state = reactive({
            invaildFields: [] as InvaildPlaceField[],
            searching: false,
        })

        async function loadData() {
            state.searching = true
            const { invaildFields } =
                await checkMissionTemplateRelatePlaceVaild()
            state.invaildFields = invaildFields
            state.searching = false
        }

        loadData()

        return {
            ...toRefs(state),
        }
    },
})
